import { useAuthStore } from '@/stores/auth';

export default defineNuxtRouteMiddleware((to, from) => {
    // Fetch my user. If the user just get in, he will not have a accessToken => a refreshToken will be used to get a new accessToken
    const authStore = useAuthStore();

    if (authStore.isLoggedInAsClient) {
        return navigateTo('/admin');
    }

    return authStore.fetchMyUser().then((response) => {
        if (response.organisation) {
            return navigateTo('/admin');
        }
        return '/';
    }).catch((error) => {
        console.log({ error });
    });
});
